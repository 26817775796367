import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { customMedia } from '../../../common/customMedia'
import { Reveal, Animation } from 'react-genie'
import { Image } from '../../../common/Image'

export interface Props {
  setShowVideo: React.Dispatch<React.SetStateAction<boolean>>
}

export const Drawer = (props: Props) => {
  const { setShowVideo } = props
  const [windowHeight, setWindowHeight] = useState(0)
  const [menuHeight, setMenuHeight] = useState(0)
  
  const handleShowVideoClick = () => setShowVideo(true)

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight)
      setMenuHeight(window.innerHeight - 240 - 96)
    }

    window.addEventListener('resize', handleResize)

    handleResize()
  }, [])

  return (
    <>
      <Container>
        <MenuContainer menuHeight={menuHeight}>
          <MenuContent>
            <Reveal delay={500} animation={Animation.FadeIn}>
              <Link to='/about'>
                <Menu>
                  <MenuIconWrapper>
                    <MenuIconImageWrapper width={25}>
                      <Image path="drawer_about@2x.png" />
                    </MenuIconImageWrapper>
                  </MenuIconWrapper>
                  わたしたち
                </Menu>
              </Link>
            </Reveal>
            <Reveal delay={600} animation={Animation.FadeIn}>
              <Link to='/store'>
                <Menu>
                  <MenuIconWrapper>
                    <MenuIconImageWrapper width={36}>
                      <Image path="drawer_store@2x.png" />
                    </MenuIconImageWrapper>
                  </MenuIconWrapper>
                  店舗案内
                </Menu>
              </Link>
            </Reveal>
            <Reveal delay={700} animation={Animation.FadeIn}>
              <Link to='/about/company'>
                <Menu>
                  <MenuIconWrapper>
                    <MenuIconImageWrapper width={30}>
                      <Image path="drawer_company@2x.png" />
                    </MenuIconImageWrapper>
                  </MenuIconWrapper>
                  会社情報
                </Menu>
              </Link>
            </Reveal>
            <Reveal delay={800} animation={Animation.FadeIn}>
              <Link to='/recruit'>
                <Menu>
                  <MenuIconWrapper>
                    <MenuIconImageWrapper width={28}>
                      <Image path="drawer_recruit@2x.png" />
                    </MenuIconImageWrapper>
                  </MenuIconWrapper>
                  採用情報
                </Menu>
              </Link>
            </Reveal>
          </MenuContent>
        </MenuContainer>
        
        <BalloonWrapper windowHeight={windowHeight}>
          <WorkBalloon onClick={handleShowVideoClick}>
            <WorkIconWrapper>
              <Image path="zo_sm@2x.png" />
            </WorkIconWrapper>
            <BalloonText>
              シンビ堂の<br/>お仕事
            </BalloonText>
          </WorkBalloon>

          <Link to='/kakaritsuke'>
            <KakaritsukeBalloon>
              <KakaritsukeIconWrapper>
                <Image path="drawer_binder@2x.png" />
              </KakaritsukeIconWrapper>
              <KakaritsukeText>
                かかりつけ<br/>薬剤師制度<br/>って？
              </KakaritsukeText>
            </KakaritsukeBalloon>
          </Link>

          <Link to='/generic'>
            <GenericBalloon>
              <GenericIconWrapper>
                <Image path="drawer_tablet@2x.png" />
              </GenericIconWrapper>
              <BalloonText>
                ジェネリックの<br/>医薬品って？
              </BalloonText>  
            </GenericBalloon>
          </Link>

          <Link to='/note'>
            <NoteBalloon>
              <NoteIconWrapper>
                <Image path="drawer_note@2x.png" />
              </NoteIconWrapper>
              <BalloonText>
                お薬手帳を<br/>活用しよう
              </BalloonText>
            </NoteBalloon>
          </Link>

          <DoctorImageWrapper>
            <Image path="drawer_doctor@2x.png" />
          </DoctorImageWrapper>
        </BalloonWrapper>

      </Container>
    </>
  )
}

const Container = styled.div`
  ${customMedia.lessThan("medium")`
    left: 0;
  `}
  ${customMedia.greaterThan("medium")`
    width: 460px;
  `}

  background-color: #CA2F68;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  font-family: 'Kosugi Maru', sans-serif;
`

const MenuContainer = styled.div<{menuHeight: number}>`
  ${props => customMedia.lessThan("medium")`
    position: absolute;
    left: 50%;
    margin: 96px auto 0;
    height: ${props.menuHeight}px;
    transform: translateY(0%) translateX(-50%);
    -webkit-transform: translateY(0%) translateX(-50%);
    width: 100%;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 150px 0 0 124px;
  `}
`

const MenuContent = styled.div`
  ${customMedia.lessThan("medium")`
    position: absolute;
    transform: translateY(-50%) translateX(-50%);
    -webkit-transform: translateY(-50%) translateX(-50%);
    top: 50%;
    left: 50%;
  `}
`

const Menu = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.5rem;
    height: 25px;
    line-height: 25px;
    letter-spacing: 0.34em;
    margin-bottom: 30px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 2.1rem;
    height: 30px;
    line-height: 30px;
    letter-spacing: 0.34em;
    margin-bottom: 40px;
    vertical-align: center;
  `}
    
  display: -webkit-flex;
  display: flex;
  color: #fff;
`

const MenuIconWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    width: 50px
  `}
  ${customMedia.greaterThan("medium")`
    width: 70px
  `}
`

const MenuIconImageWrapper = styled.div<{width: number}>`
  ${props => customMedia.lessThan("medium")`
    margin: 0 auto;
    width: ${props.width - 8 + 'px'};
  `}
  ${props => customMedia.greaterThan("medium")`
    margin: 0 auto;
    width: ${props.width + 'px'};
  `}
`

const BalloonWrapper = styled.div<{windowHeight: number}>`
  ${props => customMedia.greaterThan("medium")`
    display: ${props.windowHeight > 720 ? 'block' : 'none'};
  `}
`

const DoctorImageWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    width: 87px;
  `}
  ${customMedia.greaterThan("medium")`
    width: 120px;
  `}

  bottom: 0;
  left: 50%;
  position: absolute;
  transform: translateY(0%) translateX(-50%);
  -webkit-transform: translateY(0%) translateX(-50%);
`

const WorkBalloon = styled.div`
  ${customMedia.lessThan("medium")`
    bottom: 130px;
    height: 102px;
    left: 52px;
    width: 102px;
    left: calc(50% - 60px);
    transform: translateY(0%) translateX(-50%);
    -webkit-transform: translateY(0%) translateX(-50%);

    :before {
      border-width: 20px 5px 0 5px;
      bottom: -15px;
      margin-left: -5px;
    }
  `}
  ${customMedia.greaterThan("medium")`
    bottom: 180px;
    left: 80px;
    height: 138px;
    width: 138px;

    :before {
      border-width: 20px 5px 0 5px;
      bottom: -17px;
      margin-left: -5px;
    }
  `}

  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  cursor: pointer;

  :before {
    content: '';
    position: absolute;
    display: block;
    z-index: 1;
    border-style: solid;
    border-color: #fff transparent;
    left: 50%;
    margin-bottom: 8px;
    margin-left: 30px;
    transform: rotate(-30deg);
  }

  :hover {
    ${customMedia.greaterThan("medium")`
      bottom: 190px;
    `}
    
  }

  transition: all .4s;
`

const WorkIconWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 10px auto 0;
    width: 36px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 13px auto 0;
    width: 50px;
  `}
`

const KakaritsukeBalloon = styled.div`
  ${customMedia.lessThan("medium")`
    bottom: 132px;
    height: 102px;
    width: 102px;
    left: calc(50% + 60px);
    transform: translateY(0%) translateX(-50%);
    -webkit-transform: translateY(0%) translateX(-50%);
  

    :before {
      border-width: 20px 5px 0 5px;
      bottom: -15px;
      margin-left: -5px;
    }
  `}
  ${customMedia.greaterThan("medium")`
    bottom: 180px;
    right: 80px;
    height: 138px;
    width: 138px;

    :before {
      border-width: 20px 5px 0 5px;
      bottom: -17px;
      margin-left: -5px;
    }
  `}

  background-color: #fff;
  border-radius: 50%;
  position: absolute;

  :before {
    content: '';
    position: absolute;
    display: block;
    z-index: 1;
    border-style: solid;
    border-color: #fff transparent;
    left: 50%;
    margin-bottom: 8px;
    margin-left: -40px;
    transform: rotate(30deg);
  }

  :hover {
    ${customMedia.greaterThan("medium")`
      bottom: 190px;
    `}
    
  }

  transition: all .4s;
`

const KakaritsukeIconWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 10px auto 0;
    width: 30px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 13px auto 0;
    width: 43px;
  `}
`

const GenericBalloon = styled.div`
  ${customMedia.lessThan("medium")`
    bottom: 20px;
    height: 102px;
    left: calc(50% - 100px);
    width: 102px;
    transform: translateY(0%) translateX(-50%);
    -webkit-transform: translateY(0%) translateX(-50%);

    :before {
      border-width: 20px 5px 0 5px;
      bottom: -17px;
      left: 50%;
      margin-bottom: 20px;
      margin-left: 35px;
      transform: rotate(-40deg);
    }
  `}
  ${customMedia.greaterThan("medium")`
    bottom: 30px;
    height: 138px;
    left: 40px;
    width: 138px;

    :before {
      border-width: 20px 5px 0 5px;
      bottom: -17px;
      left: 50%;
      margin-bottom: 20px;
      margin-left: 45px;
      transform: rotate(-40deg);
    }
  `}

  background-color: #fff;
  border-radius: 50%;
  position: absolute;

  :before {
    content: '';
    position: absolute;
    display: block;
    z-index: 1;
    border-style: solid;
    border-color: #fff transparent;
  }

  :hover {
    ${customMedia.greaterThan("medium")`
      bottom: 40px;
    `}
    
  }

  transition: all .4s;
`

const GenericIconWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 11px auto 0;
    width: 47px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 16px auto 0;
    width: 65px;
  `}
`

const NoteBalloon = styled.div`
  ${customMedia.lessThan("medium")`
    bottom: 20px;
    height: 102px;
    right: calc(50% - 100px);
    width: 102px;
    transform: translateY(0%) translateX(50%);
    -webkit-transform: translateY(0%) translateX(50%);

    :before {
      border-width: 20px 5px 0 5px;
      bottom: -17px;
      left: 50%;
      margin-bottom: 20px;
      margin-left: -45px;
      transform: rotate(40deg);
    }
  `}
  ${customMedia.greaterThan("medium")`
    bottom: 30px;
    height: 138px;
    right: 40px;
    width: 138px;

    :before {
      border-width: 20px 5px 0 5px;
      bottom: -17px;
      left: 50%;
      margin-bottom: 20px;
      margin-left: -55px;
      transform: rotate(40deg);
    }
  `}

  background-color: #fff;
  border-radius: 50%;
  position: absolute;

  :before {
    content: '';
    position: absolute;
    display: block;
    z-index: 1;
    border-style: solid;
    border-color: #fff transparent;
  }

  :hover {
    ${customMedia.greaterThan("medium")`
      bottom: 40px;
    `}
    
  }

  transition: all .4s;
`

const NoteIconWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 15px auto 0;
    width: 37px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 20px auto 0;
    width: 50px;
  `}
`

const BalloonText = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.2rem;
    margin-top: 5px;
    line-height: 16px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.6rem;
    margin-top: 12px;
    line-height: 20px;
  `}
  
  color: #0055A3;
  font-weight: bold;
  text-align: center;
`

const KakaritsukeText = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.2rem;
    margin-top: 5px;
    line-height: 16px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.6rem;
    margin-top: 6px;
    line-height: 20px;
  `}
  
  color: #0055A3;
  font-weight: bold;
  text-align: center;
`

const Hamburger = styled.a<{open: boolean}>`
  ${props => customMedia.lessThan("medium")`
    height: 16px;
    right: 18px;
    width: 20px;

    span {
      height: 2px;

      :nth-of-type(1) {
        top: 0;
        ${props.open && `
          -webkit-transform: translateY(7px) rotate(-45deg);
          transform: translateY(7px) rotate(-45deg);
        `}
      }
  
      :nth-of-type(2) {
        top: 7px;
        ${props.open && 'opacity: 0;'}
      }
  
      :nth-of-type(3) {
        bottom: 0;
        ${props.open && `
          -webkit-transform: translateY(-7px) rotate(45deg);
          transform: translateY(-7px) rotate(45deg);
        `}
      }
    }
  `}
  ${props => customMedia.greaterThan("medium")`
    height: 34px;
    right: 48px;
    width: 40px;


    span {
      height: 4px;

      :nth-of-type(1) {
        top: 0;
        ${props.open && `
          -webkit-transform: translateY(15px) rotate(-45deg);
          transform: translateY(15px) rotate(-45deg);
        `}
      }
  
      :nth-of-type(2) {
        top: 15px;
        ${props.open && 'opacity: 0;'}
      }
  
      :nth-of-type(3) {
        bottom: 0;
        ${props.open && `
          -webkit-transform: translateY(-15px) rotate(45deg);
          transform: translateY(-15px) rotate(45deg);
        `}
      }
    }
  `}

  cursor: pointer;
  display: inline-block;
  transition: all .4s;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(0%);
  -webkit-transform: translateY(-50%) translateX(0%);

  span {
    display: inline-block;
    transition: all .4s;
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    background-color: ${props => props.open ? '#fff' : '#CB2F68'};
    border-radius: 10px;
  }
`