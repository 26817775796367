import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { customMedia } from '../../common/customMedia'
import { Image } from '../../common/Image'
import { SpBr } from '../../common/SpBr'

const PcCoverImage = require('../../../images/footer_check_pc.jpg')
const SpCoverImage = require('../../../images/footer_check_sp.jpg')

export interface Props {
  siteTitle: string
}

export const Footer = (props: Props) => {
  return (
    <>
      <Container>
        <Wrapper>
          <LogoWrapper>
            <Link to="/">
              <Image path="footer_logo@2x.png" />
            </Link>
          </LogoWrapper>
          <LinkContainer>
            <Link to="/about">わたしたち</Link>
            <Divider>|</Divider>
            <Link to="/store">店舗案内</Link>
            <Divider>|</Divider>
            <Link to="/about/company">会社情報</Link>
            <Divider>|</Divider>
            <Link to="/recruit">採用情報</Link>
            <Divider>|</Divider>
            <Link to="/generic">ジェネリック<SpBr />医薬品って？</Link>
            <Divider>|</Divider>
            <Link to="/note">お薬手帳に<SpBr />ついて</Link>
            <Divider>|</Divider>
            <Link to="/kakaritsuke">かかりつけ<SpBr />薬剤師制度とは？</Link>
            <Divider>|</Divider>
            <Link to="/">HOME</Link>
          </LinkContainer>

          <SpCheckCoverWrapper>
            <img src={SpCoverImage} alt={SpCoverImage} />
          </SpCheckCoverWrapper>
          <PcCheckCoverWrapper>
            <img src={PcCoverImage} alt={PcCoverImage} />
          </PcCheckCoverWrapper>
          <CopyRight>(C)SHINBIDO</CopyRight>
        </Wrapper>
      </Container>
    </>
  )
}

const Container = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
    height: 407px;
  `}

  background-color: #CA2F68;
`

const Wrapper = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}
  overflow: hidden;
`

const LogoWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    display: none;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 32px auto 0;
    width: 200px;
  `}
`

const LinkContainer = styled.div`
  ${customMedia.lessThan("medium")`
    a {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      align-items: center;
      -webkit-justify-content: center;
      justify-content: center;
      border-top: 1.5px #fff solid;
      float: left;
      height: 50px;
      text-align: center;
      vertical-align: middle;
      width: 50%;
    }
    
    a:nth-of-type(odd) {
      border-right: 1.5px #fff solid;
    }

    // a:last-child {
    //   border-right: none;
    //   border-bottom: 1.5px #fff solid;
    //   width: 100%;
    // }
  `}
  ${customMedia.greaterThan("medium")`
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    margin-top: 40px;
  `}

  color: #fff;
  font-size: 1.2rem;

  a {
    color: #fff;
    letter-spacing: 0.12em;
  }
`

const Divider = styled.div`
  ${customMedia.lessThan("medium")`
    display: none;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 0 20px;
  `}
`

const SpCheckCoverWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin-top: 40px;
    img {
      height: 63px;
      object-fit: cover;
      overflow: hidden;
      width: 100%;
    }
  `}
  ${customMedia.greaterThan("medium")`
    display: none;
  `}
`

const PcCheckCoverWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    display: none;
  `}
  ${customMedia.greaterThan("medium")`
    margin-top: 40px;
    img {
      height: 177px;
      object-fit: cover;
      overflow: hidden;
      width: 100%;
    }
  `}
`

const CopyRight = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.2rem;
    margin: 5px 0;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.4rem;
    margin-top: 14px;
  `}

  color: #fff;
  font-family: 'Quicksand', sans-serif;
  text-align: center;
`