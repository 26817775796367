import React from 'react'
import styled from 'styled-components'
import { customMedia } from '../../common/customMedia'

const Video = require('../../../videos/shinbi-do.mp4')

export interface Props {
  setShowVideo: React.Dispatch<React.SetStateAction<boolean>>
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const PromotionVideo = (props: Props) => {
  const { setOpen, setShowVideo } = props
  
  const handleCloseVideoClick = () => {
    setShowVideo(false)
    // setOpen(false)
  }

  return (
    <Container>
      <VideoWrapper>
        <video controls>
          <source src={Video} type="video/mp4" />
        </video>
      </VideoWrapper>
      <CloseIcon onClick={handleCloseVideoClick}>
        <span />
        <span />
      </CloseIcon>
    </Container>
  )
}

const Container = styled.div`
  background-color: rgba(196, 47, 104, .9);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;

  z-index: 100000;
`

const VideoWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin-top: 23px;

    video {
      width: 100%;
    }
  `}
  ${customMedia.between("medium", "large")`
    margin-top: 38px;

    video {
      width: 768px;
    }
  `}
  ${customMedia.greaterThan("large")`
    margin-top: 38px;

    video {
      width: 900px;
    }
  `}

  video {
    display: block;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    -webkit- transform: translateY(-50%) translateX(-50%);
  }
`

const CloseIcon = styled.div`
  ${customMedia.lessThan("medium")`
    span {
      height: 2px;
      border-radius: 10px;
      right: 18px;
      top: 18px;

      :nth-of-type(1) {
        webkit-transform: translateY(7px) rotate(45deg);
        transform: translateY(7px) rotate(45deg);
        width: 20px;
      }

      :nth-of-type(2) {
        webkit-transform: translateY(7px) rotate(-45deg);
        transform: translateY(7px) rotate(-45deg);
        width: 20px;
      }
    }
  `}
  ${customMedia.greaterThan("medium")`
    span {
      height: 4px;
      border-radius: 10px;
      right: 40px;
      top: 40px;

      :nth-of-type(1) {
        webkit-transform: translateY(7px) rotate(45deg);
        transform: translateY(7px) rotate(45deg);
        width: 40px;
      }

      :nth-of-type(2) {
        webkit-transform: translateY(7px) rotate(-45deg);
        transform: translateY(7px) rotate(-45deg);
        width: 40px;
      }
    }
  `}

  span {
    display: inline-block;
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    background-color: #fff;
  }

  cursor: pointer;
`
