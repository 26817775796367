import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import BurgerMenu from 'react-burger-menu'
import { customMedia } from '../../common/customMedia'
import { SpOnly } from '../../common/SpOnly'
import { PcOnly } from '../../common/PcOnly'
import { Image } from '../../common/Image'
import { Drawer } from './Drawer'
import { PromotionVideo } from '../../common/PromotionVideo'

export interface Props {
  siteTitle: string
}

export const Header = (props: Props) => {
  const [open, setOpen] = useState(false)
  const [showVideo, setShowVideo] = useState(false)
  const [currentScrollPosition, setCurentScollPosition] = useState(0)
  const [headerHeight, setHeaderHeight] = useState(180)

  const handleHamburgerClick = () => setOpen(!open)

  const handleScroll = () => {
    if (window.scrollY === 0) {
      setHeaderHeight(180)
    } else if (0 < window.scrollY && window.scrollY <= 560) {
      setHeaderHeight(180 - ((window.scrollY)/7))
    } else {
      setHeaderHeight(100)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll,)
  }, [])

  useEffect(() => {
    console.log(open)
    if (open) {
      window.document.querySelector('html').style.overflow = 'hidden'
    } else {
      window.document.querySelector('html').style.overflow = 'scroll'
    }
  }, [open])

  return (
    <>
      <Container headerHeight={headerHeight}>
        <Content headerHeight={headerHeight}>
          <LogoWrapper>
            <Link to="/">
              <Image path="header_logo@2x.png" />
            </Link>
          </LogoWrapper>
        </Content>
      </Container>

      <Hamburger onClick={handleHamburgerClick} open={open} headerHeight={headerHeight}>
        <span />
        <span />
        <span />
      </Hamburger>
      {open && <Drawer setShowVideo={setShowVideo} />}
      
      {showVideo && <PromotionVideo setShowVideo={setShowVideo} setOpen={setOpen} />}
    </>
  )
}

const Container = styled.div<{headerHeight: number}>`
  ${customMedia.lessThan("medium")`
    height: 57px;
  `}
  ${props => customMedia.greaterThan("medium")`
    height: ${props.headerHeight}px;
  `}

  background-color: #fff;
  position: fixed;
  width: 100%;
  z-index: 100;
`

const Content = styled.div<{headerHeight: number}>`
  ${customMedia.lessThan("medium")`
    height: 57px;
  `}
  ${props => customMedia.greaterThan("medium")`
    // height: 180px;
    height: ${props.headerHeight}px;

  `}

  background-color: #fff;
  position: relative;
  width: 100%;
`

const LogoWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    width: 120px;
  `}
  ${customMedia.greaterThan("medium")`
    width: 240px;
  `}
  
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
  position: absolute;
  z-index: 100;
`

const Hamburger = styled.a<{open: boolean, headerHeight: number}>`
  ${props => customMedia.lessThan("medium")`
    height: 16px;    
    right: 18px;
    top: 30px;
    width: 20px;

    span {
      height: 2px;

      :nth-of-type(1) {
        top: 0;
        ${props.open && `
          -webkit-transform: translateY(7px) rotate(-45deg);
          transform: translateY(7px) rotate(-45deg);
        `}
      }
  
      :nth-of-type(2) {
        top: 7px;
        ${props.open && 'opacity: 0;'}
      }
  
      :nth-of-type(3) {
        bottom: 0;
        ${props.open && `
          -webkit-transform: translateY(-7px) rotate(45deg);
          transform: translateY(-7px) rotate(45deg);
        `}
      }
    }
  `}
  ${props => customMedia.greaterThan("medium")`
    height: 34px;
    right: 48px;
    top: ${props.headerHeight/2}px;
    width: 40px;

    span {
      height: 4px;

      :nth-of-type(1) {
        top: 0;
        ${props.open && `
          -webkit-transform: translateY(15px) rotate(-45deg);
          transform: translateY(15px) rotate(-45deg);
        `}
      }
  
      :nth-of-type(2) {
        top: 15px;
        ${props.open && 'opacity: 0;'}
      }
  
      :nth-of-type(3) {
        bottom: 0;
        ${props.open && `
          -webkit-transform: translateY(-15px) rotate(45deg);
          transform: translateY(-15px) rotate(45deg);
        `}
      }
    }
  `}

  cursor: pointer;
  display: inline-block;
  transition: all .4s;
  box-sizing: border-box;
  position: fixed;
  transform: translateY(-50%) translateX(0%);
  -webkit-transform: translateY(-50%) translateX(0%);
  z-index: 1000;

  span {
    display: inline-block;
    transition: all .4s;
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    background-color: ${props => props.open ? '#fff' : '#CB2F68'};
    border-radius: 10px;
  }
`