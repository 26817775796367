/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from 'gatsby'

import styled from 'styled-components'
import { customMedia } from '../common/customMedia'
import { Header } from './Header'
import { Footer } from './Footer'
import { Image } from '../common/Image'
import './layout.css'

export const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const links = [
    { text: 'Company', path: 'company' },
    { text: 'Science', path: 'science' },
    { text: 'Recruit', path: 'recruit' },
    { text: 'News', path: 'news' },
    { text: 'Member', path: 'member' }
  ]

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <Main>{children}</Main>
      <Footer siteTitle={data.site.siteMetadata.title} />
    </>
  )
}

const Main = styled.main`
  ${customMedia.lessThan("medium")`
    padding-top: 57px;
  `}
  ${customMedia.greaterThan("medium")`
    padding-top: 180px;
  `}
`

// Layout.propTypes = {
//   children: PropTypes.node.isRequired
// }

// const LinkWrapper = styled.div`
//   ${customMedia.lessThan("large")`
//     margin-top: 40px;
//   `}
//   ${customMedia.greaterThan("large")`
//     display: -webkit-flex;
//     display: flex;
//     -webkit-justify-content: flex-start;
//     justify-content: flex-start;
//     -webkit-align-items: center;
//     align-items: center;
//     margin: 40px auto 0;
//     padding: 0 100px;
//   `}
// `

// const LogoImageWrapper = styled.div`
//   ${customMedia.lessThan("medium")`
//     margin: 0 auto 20px;
//     width: 138px;
//   `}
//   ${customMedia.between("medium", "large")`
//     margin: 0 auto 20px;
//     width: 200px;
//   `}
//   ${customMedia.greaterThan("large")`
//     min-width: 200px;
//     padding-bottom: 10px;
//   `}
// `

// const TextLinkWrapper = styled.div`
//   ${customMedia.lessThan("large")`
//     -webkit-flex-wrap: wrap;
//     flex-wrap: wrap;
//     margin: 0 auto 0 15%;
//   `}
//   ${customMedia.greaterThan("large")`
//     -webkit-justify-content: flex-end;
//     justify-content: flex-end;
//     -webkit-align-items: flex-end;
//     align-items: flex-end;
//     margin-left: auto;
//     width: 80%;
//   `}

//   display: -webkit-flex;
//   display: flex;
// `

// const TextLink = styled.div`
//   ${customMedia.lessThan("medium")`
//     font-size: 1.2rem;
//     margin-bottom: 20px;
//     width: calc(100% / 3);
//   `}
//   ${customMedia.between("medium", "large")`
//     font-size: 2rem;
//     margin-bottom: 20px;
//     width: calc(100% / 3);
//   `}
//   ${customMedia.greaterThan("large")`
//     -webkit-flex-grow: 1;
//     flex-grow: 1;
//     font-size: 2rem;
//     text-align: right;
//   `}

//   a {
//     color: #26d2ad;
//     font-weight: bold;

//     :hover {
//       color: #a3efde;
//       -webkit-transition: all .3s;
//       transition: all .1s;
//     }
//   }
// `

// const SnsIconWrapper = styled.div`
//   ${customMedia.greaterThan("large")`
//     display: none;
//   `}

//   display: -webkit-flex;
//   display: flex;
//   -webkit-justify-content: space-between;
//   justify-content: space-between;
//   margin: 0 auto 20px;
//   width: 77px;
// `

// const SpFbIconWrapper = styled.div`
//   width: 25px;
// `

// const PcFbIconWrapper = styled.div`
//   ${customMedia.lessThan("large")`
//     display: none;
//   `}
//   ${customMedia.greaterThan("large")`
//     margin-left: 55px;
//     width: 24px;
//   `}

//   padding-bottom: 5px;
// `

// const SpTwIconWrapper = styled.div`
//   padding-top: 2px;
//   width: 25px;
// `

// const PcTwIconWrapper = styled.div`
//   ${customMedia.lessThan("large")`
//     display: none;
//   `}
//   ${customMedia.greaterThan("large")`
//     margin-left: 30px;
//     width: 24px;
//   `}

//   padding-bottom: 5px;
// `

// const PrivacyPolicyLink = styled.div`
//   ${customMedia.greaterThan("medium")`
//     display: none;
//   `}

//   margin-left: -4px;
  
//   a {
//     color: #60656b;
//   }
// `

// const Footer = styled.div`
// ${customMedia.lessThan("medium")`
//   font-size: 0.7rem;
//   margin-bottom: 10px;
//   padding: 10px 20px;
// `}
// ${customMedia.greaterThan("medium")`
//   font-size: 1.4rem;
//   margin: 0 auto;
//   padding: 40px 100px;
// `}

//   color: #60656b;
//   display: -webkit-flex;
//   display: flex;
//   -webkit-justify-content: flex-start;
//   justify-content: flex-start;
// `

// const Copyright = styled.div``

// const PrivacyPolicy = styled.div`
//   ${customMedia.lessThan("medium")`
//     display: none;
//   `}

//   margin-left: auto;

//   a {
//     color: #60656b;
//   }
// `

// const GoToTopButton = styled.div`
//   ${customMedia.greaterThan("medium")`
//     display: none;
//   `}

//   -webkit-box-sizing: content-box;
//   -moz-box-sizing: content-box;
//   box-sizing: content-box;
//   background-color: #26d2ad;
//   border: 2.5px #fff solid;
//   border-radius: 50%;
//   color: #fff;
//   cursor: pointer;
//   font-size: 1.9rem;
//   font-weight: bold;
//   line-height: 65px;
//   position: fixed;
//   text-align: center;
//   height: 65px;
//   width: 65px;

//   right: 20px;
//   bottom: 20px;

//   :focus {
//     outline:0;
//   }

//   :active {
//     outline:0;
//   }

//   a {
//     color: #fff;
//   }
// `